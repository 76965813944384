import React, { useRef } from "react";
import { useHasBeenAlmostVisible } from "@italymondo/core-ui";
import { SignatureBlockContainer } from "./styles";

type PpSignatureBlock = {
  signature: string | undefined;
};

export function SignatureBlock({
  signature,
}: PpSignatureBlock): React.ReactElement {
  const elementRef = useRef<HTMLDivElement>(null);
  const shouldRenderContent = useHasBeenAlmostVisible(elementRef);

  return (
    <SignatureBlockContainer ref={elementRef}>
      {shouldRenderContent ? <img alt="" src={signature} /> : <div />}
    </SignatureBlockContainer>
  );
}
